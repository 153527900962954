<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <Calendar :is-widget="false" :status="status" />
        </v-col>

    </v-row>

</template>

<script>
import Calendar from '@/components/Calendar';

export default {
    components:{
        Calendar,
    }, 
    props: {
        status: {
            type: String,
            default: null
        }
    },   
}
</script>